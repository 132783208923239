import {
	AspectRatio,
	HStack,
	Image,
	Skeleton,
	Text,
	VStack,
	Link,
	IconButton,
	useToast,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardProps,
	Heading,
} from '@chakra-ui/react';
import { FiEye, FiHeart } from 'react-icons/fi';
import { IProductQuickViewModalProps } from './ProductQuickViewModal';
import { IRewardTypeDto } from '../../../domain/models';
import {
	NavLink,
	// useNavigate
} from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../data/globalstate';
import { useCartManager } from '../../../domain/hooks';
import { FaShoppingCart } from 'react-icons/fa';
import { isNumber } from 'lodash';
import { shortenNumber } from '../../../domain/utils/GeneralUtils';

interface Props {
	product: IRewardTypeDto;
	quickViewProps: IProductQuickViewModalProps;
	setQuickViewProps: React.Dispatch<IProductQuickViewModalProps>;
	cardProps?: CardProps;
	hideButtons?: boolean;
}

const PointsCircle: React.FC<{ points: number }> = ({ points }) => {
	return (
		<VStack
			w={'72px'}
			h={'72px'}
			// py={4}
			gap={0}
			borderRadius={'50%'}
			border={'2px solid #fff'}
			backgroundColor={'primary.500'}
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			color={'white'}
			position={'absolute'}
			top={0}
			right={'50%'}
			zIndex={10}
		>
			<Heading as={'span'} size={'md'} fontWeight={'bold'} lineHeight={'1em'}>
				{/* {shortenNumber(points).toLocaleString('en-US')} */}
				{shortenNumber(points)}
			</Heading>
			<Text fontSize={{ base: 'sm' }} textTransform={'uppercase'} lineHeight={'0.8em'}>
				Points
			</Text>
		</VStack>
	);
};

const ProductCard: React.FC<Props> = ({ product, setQuickViewProps, cardProps, hideButtons }) => {
	const { user, appConfiguration, setShowAuth, showAuth } = useContext(AppContext);
	// const navigate = useNavigate();
	const { useAddCartItem } = useCartManager();
	const { mutate: addCartItem } = useAddCartItem();
	const toast = useToast();
	let primaryImageUrl = product.imageURL;
	let primaryAltText = product.voucherName;

	if (!primaryImageUrl) {
		const match = product.imageAlternatives?.filter((v) => {
			return v.isPrimary;
		});
		if (match) {
			primaryImageUrl = match[0]?.url;
			primaryAltText = match[0]?.altText;
		}
	}

	return (
		<Card borderRadius={'lg'} {...cardProps}>
			<CardHeader
				p={3}
				backgroundColor={
					product.voucherTypeSubCategory === 'SWEEPSTAKE' ? 'secondary.500' : undefined
				}
				borderTopRadius={'lg'}
			>
				<HStack alignSelf={'stretch'} justifyContent={'center'}>
					{product.voucherTypeSubCategory === 'SWEEPSTAKE' && (
						<HStack
							justifyContent={'space-between'}
							textColor={'white'}
							alignSelf={'stretch'}
							flex={1}
							alignItems={'center'}
						>
							<Text textTransform={'uppercase'} fontSize={'medium'}>
								sweepstakes
							</Text>
							<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
								<PointsCircle points={product.points ?? 0} />
								{!hideButtons && (
									<IconButton
										icon={<FiEye size={'24px'} />}
										aria-label="View details"
										onClick={() => {
											setQuickViewProps({ isShown: true, product: product });
										}}
										w={'24px'}
										h={'24px'}
										variant={'ghost'}
										color={'white'}
									/>
								)}
							</HStack>
						</HStack>
					)}
					{product.voucherTypeSubCategory !== 'SWEEPSTAKE' && (
						<HStack
							justifyContent={'space-between'}
							alignSelf={'stretch'}
							flex={1}
							alignItems={'center'}
						>
							{product.quantity === null && (
								<Text textTransform={'uppercase'} fontSize={'medium'}>
									sold out
								</Text>
							)}
							{/* TODO Update to support hiding qty but not hiding sold out! */}
							{product.quantity !== null && (
								<Text textTransform={'uppercase'} fontSize={'medium'} fontWeight={'bold'}>
									{product.quantity > 0 ? `${product.quantity.toString()} available` : 'sold out'}
								</Text>
							)}
							<HStack flex={1} gap={0} justifyContent={'flex-end'} position={'relative'}>
								<PointsCircle points={product.points ?? 0} />
								{!hideButtons && (
									<>
										<IconButton
											icon={<FiHeart size={'24px'} />}
											aria-label="Add to wishlist"
											onClick={() => {
												// TODO Wishlist
												console.log('Add to Wishlist', product);
											}}
											w={'24px'}
											h={'24px'}
											variant={'ghost'}
											zIndex={20}
										/>
										<IconButton
											icon={<FiEye size={'24px'} />}
											aria-label="View details"
											onClick={() => {
												setQuickViewProps({ isShown: true, product: product });
											}}
											w={'24px'}
											h={'24px'}
											variant={'ghost'}
											zIndex={20}
										/>
									</>
								)}
							</HStack>
						</HStack>
					)}
				</HStack>
			</CardHeader>
			<CardBody py={0} pt={1}>
				<Link as={NavLink} to={product.rewardId ? `/product/${product.rewardId}` : '#'}>
					<AspectRatio ratio={1} borderRadius="lg" backgroundColor={'#FFF'}>
						<Image
							src={primaryImageUrl ?? appConfiguration?.placeHolderImage ?? undefined}
							alt={primaryAltText ?? 'Unknown'}
							draggable="false"
							fallback={<Skeleton />}
							borderRadius="lg"
							// @ts-expect-error Need to add CSS !important
							objectFit={'contain !important'}
						/>
					</AspectRatio>
				</Link>
				<Link
					as={NavLink}
					to={product.rewardId ? `/product/${product.rewardId}` : '#'}
					fontSize={{ base: 'md' }}
					fontWeight={'bold'}
					textAlign={'left'}
					textTransform={'uppercase'}
				>
					{product.voucherName}
				</Link>
			</CardBody>
			<CardFooter pt={0}>
				<HStack flex={1} justifyContent={'flex-end'}>
					<IconButton
						aria-label="Add to Cart"
						icon={<FaShoppingCart size={'24px'} />}
						variant={'ghost'}
						disabled={
							!isNumber(product.points) ||
							product.quantity === 0 ||
							(user && product.points > (user.balance ?? 0))
						}
						isDisabled={
							!isNumber(product.points) ||
							product.quantity === 0 ||
							(user && product.points > (user.balance ?? 0))
						}
						onClick={() => {
							if (user) {
								addCartItem({ itemData: product, quantity: 1 });
								toast({
									// title: 'Account created.',
									description: `Added ${product.voucherName ?? ''} to your cart!`,
									status: 'success',
									duration: 5000,
									isClosable: true,
								});
							} else {
								// navigate('/login');
								// Show Auth Popup
								setShowAuth(!showAuth);
							}
						}}
					/>
				</HStack>
			</CardFooter>
		</Card>
	);
};
export default ProductCard;
