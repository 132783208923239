export const objectToUrlParams = (obj: unknown): string => {
	let s = '';
	for (const key in obj as object) {
		// @ts-expect-error Allow Indexing of Generic Object
		if (obj[key] !== undefined) {
			if (s !== '') {
				s += '&';
			}

			// @ts-expect-error Allow Indexing of Generic Object
			// eslint-disable-next-line
			s += key + '=' + encodeURIComponent(obj[key]);
		}
	}
	// console.log("'" + s + "'");
	return s;
};

export const isEven = (number: number) => {
	return number % 2 === 0;
};

export const shortenNumber = (num: number, decimalPlaces = 1) => {
	const suffixes = ['', 'K', 'M', 'B', 'T'];

	let suffixIndex = 0;
	while (num >= 1000 && suffixIndex < suffixes.length - 1) {
		num /= 1000;
		suffixIndex++;
	}

	// Conditionally remove .0 as there is no need to display the decimal place for 0.
	if (num === Math.floor(num)) {
		decimalPlaces = 0;
	}

	return `${num.toFixed(decimalPlaces)}${suffixes[suffixIndex]}`;
};
